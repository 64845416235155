import * as React from "react"
import Carousel from "flat-carousel";
import Wrapper from "../components/wrapper"
import Header from "../components/header"
import Footer from "../components/footer"
import PluginCard from "../components/plugin-card"
import DeadProject from "../components/dead-project"

import stellarLogo from "../images/stellar-logo.svg"
import stellar1 from "../images/stellar.png"
import stellar2 from "../images/stellar-2.png"
import stellar3 from "../images/stellar-3.png"
import stellar4 from "../images/stellar-4.png"

import figmaArc from "../images/figma-arc.png"
import figmaAvatars from "../images/figma-avatars.png"
import figmaBlobs from "../images/figma-blobs.png"
import figmaFlowkit from "../images/figma-flowkit.png"
import figmaSkewdat from "../images/figma-skewdat.png"
import figmaJumpback from "../images/figma-jumpback.png"

const plugins = [
  {
    name: "Avatars",
    description: "Add real user avatars",
    image: figmaAvatars,
    url: "https://www.figma.com/community/plugin/739659977030056719/Avatars"
  },
  {
    name: "Blobs",
    description: "Generate random blob shapes",
    image: figmaBlobs,
    url: "https://www.figma.com/community/plugin/739208439270091369/Blobs"
  },
  {
    name: "SkewDat",
    description: "Skew elements",
    image: figmaSkewdat,
    url: "https://www.figma.com/community/plugin/741472919529947576/SkewDat"
  },
  {
    name: "FlowKit",
    description: "Add flows from FlowKit 3.0",
    image: figmaFlowkit,
    url: "https://www.figma.com/community/plugin/777287184344500379/Flowkit"
  },
  {
    name: "Arc",
    description: "Curve text",
    image: figmaArc,
    url: "https://www.figma.com/community/plugin/762070688792833472/arc"
  },
  {
    name: "JumpBack",
    description: "Bookmark canvas positions",
    image: figmaJumpback,
    url: "https://www.figma.com/community/plugin/1125156339032115371"
  }
]

const images = [
  // {
  //   src: stellar1
  // },
  {
    src: stellar2
  },
  {
    src: stellar3
  },
  {
    src: stellar4
  },
]

const ProjectsPage = () => {

  return (
    <Wrapper
      title="Projects - Dylan Feltus"
      description="Some of the projects I'm currently working on or launched in the past."
      path="/projects"
    >
      <Header className="bg-black text-white" navLinkClass="hover:bg-white/5" title="Things I've built" />
      <div className="container">
        <div className="flex flex-col items-center md:flex-row pt-20 pb-24">
          <Carousel>
            {images.map((image, index) => (
              <div
                key={index}
                className="border border-gray-100 rounded-lg max-w-full"
                style={{ backgroundImage: "url(" + image.src + ")" }}
              />
            ))}
          </Carousel>
          <div className="flex flex-grow flex-col mt-12 md:mt-0 md:ml-12 items-start">
            <div className="flex items-center mb-4">
              <img src={stellarLogo} className="mr-5" />
              <div className="px-2 text-2xs bg-gray-100 text-gray-600 inline-block rounded-full pointer-events-none">Coming soon</div>
            </div>
            <p className="text-sm text-gray-800">All of the tools you need to collect & triage feedback, organize roadmaps, and share updates with your customers.</p>
            <a href="https://usestellar.com" className="inline-block text-sm mt-6 text-black bg-white no-underline p-1.5 -m-1.5 rounded hover:bg-gray-50 transition ease-in-out duration-200">Join the waitlist -></a>
          </div>
        </div>
        <div className="pb-24" id="figma">
          <h2 className="text-lg md:text-xl mb-4">Figma plugins</h2>
          <p className="text-base text-gray-800">I’ve built a few plugins - a couple for myself and some for clients.</p>
          <div className="mt-12 grid grid-cols-1 gap-10 md:grid-cols-3">
            {plugins.map((p => (
              <PluginCard name={p.name} description={p.description} image={p.image} url={p.url} />
            )))}
          </div>
        </div>
        <div>
          <h2 className="text-lg md:text-xl mb-4">Project graveyard ☠️</h2>
          <p className="text-base text-gray-800">Some of the projects I'm no longer working on.</p>
          <div className="mt-20 mb-20">
            <DeadProject name="Motiv" description="Business management app for freelancers" date="Closed in 2016" />
            <DeadProject name="Freelance.chat" description="Slack community for freelancers" date="Sold in 2017" />
            <DeadProject name="FoundersKit" description="Exclusive discounts for early stage startups" date="Sold in 2015" />
            <DeadProject name="Jobscribe" description="Remote jobs in your inbox" date="Sold in 2015" />
            <DeadProject name="WP Mobile Content" description="WordPress plugin to display device specific content" date="Deprecated in 2014" />
          </div>
        </div>
      </div>
      <Footer />
    </Wrapper>
  )
}

export default ProjectsPage
