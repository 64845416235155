import React from "react"

const DeadProject = ({ name, description, date, ...props }) => {
  return(
    <div className="flex flex-col md:flex-row mb-12 text-sm">
      <div className="flex-grow">
        <h5 className="text-gray-800 font-medium md:mr-4 block md:inline">{name}</h5>
        <p className="text-gray-600 inline">{description}</p>
      </div>
      <p className="text-gray-500 mt-4 md:mt-0">{date}</p>
    </div>
  )
}

export default DeadProject
