import React from "react"

const PluginCard = ({ name, description, image, url, ...props }) => {
  return(
    <a href={url} className="border border-gray-100 rounded-lg p-8 no-underline text-gray-800 hover:text-black hover:border-gray-150 transition ease-in-out duration-200" target="_blank">
      <img src={image} className="w-14 h-14 mb-6" />
      <h3 className="mb-3">{name}</h3>
      <p className="text-sm text-gray-600 font-normal">{description}</p>
    </a>
  )
}

export default PluginCard
